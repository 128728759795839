<template>
    <!-- START BLOG -->
        <section class="section bg-light pt-5 scroll-select" id="blog">
            <div class="container">
                <div class="row margin-t-30">
                    <div class="col-lg-4">
                        <div class="career-box margin-t-30 hover-effect">
                            <div>
                                <h5 class="mt-4 text-muted">Senior Software Engineer</h5>
                                <h4 class="mt-3"><a href="" class="blog-title"> Trendyol </a></h4>
                                <p class="text-muted"><i class="mdi mdi-map-marker"></i> İstanbul</p>
                                <div class="mt-3">
                                    <a href="" class="read-btn">Daha Fazla Bilgi Edin<i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="career-box margin-t-30 hover-effect">
                            <div>
                                <h5 class="mt-4 text-muted">Digital Marketing Manager</h5>
                                <h4 class="mt-3"><a href="" class="blog-title">DataRobot</a></h4>
                                <p class="text-muted"><i class="mdi mdi-map-marker"></i> İstanbul</p>
                                <div class="mt-3">
                                    <a href="" class="read-btn">Daha Fazla Bilgi Edin <i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="career-box margin-t-30 hover-effect">
                            <div>
                                <h5 class="mt-4 text-muted">Full Stack Developer</h5>
                                <h4 class="mt-3"><a href="" class="blog-title">DataRobot</a></h4>
                                <p class="text-muted"><i class="mdi mdi-map-marker"></i> İstanbul</p>
                                <div class="mt-3">
                                    <a href="" class="read-btn">Daha Fazla Bilgi Edin <i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row margin-t-30">
                    <div class="col-lg-4">
                        <div class="career-box margin-t-30 hover-effect">
                            <div>
                                <h5 class="mt-4 text-muted">Frontend Developer</h5>
                                <h4 class="mt-3"><a href="" class="blog-title">Getir</a></h4>
                                <p class="text-muted"><i class="mdi mdi-map-marker"></i> İstanbul</p>
                                <div class="mt-3">
                                    <a href="" class="read-btn">Daha Fazla Bilgi Edin <i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="career-box margin-t-30 hover-effect">
                            <div>
                                <h5 class="mt-4 text-muted">Java Developer</h5>
                                <h4 class="mt-3"><a href="" class="blog-title">ING Türkiye</a></h4>
                                <p class="text-muted"><i class="mdi mdi-map-marker"></i> İstanbul</p>
                                <div class="mt-3">
                                    <a href="" class="read-btn">Daha Fazla Bilgi Edin <i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="career-box margin-t-30 hover-effect">
                            <div>
                                <h5 class="mt-4 text-muted">DBA - Remote</h5>
                                <h4 class="mt-3"><a href="" class="blog-title">Oracle Turkey</a></h4>
                                <p class="text-muted"><i class="mdi mdi-map-marker"></i> İstanbul</p>
                                <div class="mt-3">
                                    <a href="" class="read-btn">Daha Fazla Bilgi Edin <i class="mdi mdi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!-- END BLOG -->
</template>

<script>
export default {
  name: 'Blog',
  props: {
  }
}
</script>