
<script>
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import Careers from "@/components/careers";

/**
 * faq component
 */
export default {
  name: "career",
    components : {
        Navbar,
        Footer,
        Careers
    },
  data() {
    return {
      rotateindex: 0,
      rotate: [
        {
          item: 0,
          text: "İş İlanları",
          name: "career",
          duration: 4000,
        },
      ],
    };
  },
  mounted() {
    setInterval(() => {
      this.textRotate();
    }, this.rotate[this.rotateindex].duration);
  },

  methods: {
    /**
     * Text show one after another
     */
    textRotate() {
      setTimeout(() => {
        this.rotateindex += 1;
        if (this.rotateindex >= this.rotate.length) {
          this.rotateindex = 0;
        }
      }, 0);
    },
  },
};
</script>

<template>
<div>
    <Navbar/>
    <div v-scroll-spy>
        <!--START HOME-->
      <section
        class="section section-lg height-100vh"
        id="home"
        :style="{'background-image': 'url(' + require('@/assets/images/career/career.png') + ')' , 'background-size': 'cover', 'background-position': 'center'}"
      >
        <div class="bg-overlay"></div>
        <div class="display-table">
          <div class="display-table-cell">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 class="home-title text-rotate" :key="rotateindex">
                      {{ rotate[rotateindex].text }}
                    </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--END HOME-->
        <Careers/>
        <Footer/>
    </div>
</div>
</template>



<style>

</style>